.suppChecks:checked {
    background-color: #FF9500 !important;
}

.suggChecks:checked {
    background-color: #FF9500 !important;
}

.packChecks:checked {
    background-color: #FF9500 !important;
}