.home {
    width: 100%;
    min-height: 100vh;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), 
        url('../../public/images/tablati_home.jpeg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.gradientItem {
    background: #F13727;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #8419E2, #F13727);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #8419E2, #F13727); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */    
}